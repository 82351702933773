type Favicon = {
  href: string;
  rel: string;
  type: string;
};

type CountryField = {
  mandatory: string,
  default: string,
  prio: Array<string>
};

type Advertiser = {
  companyName: string,
  vatId?: string,
  address1?: string,
  address2?: string,
  city?: string,
  state?: string,
  postCode?: string,
  country?: CountryField,
  invoiceEmail?: string,
  phoneNumber?: string
};

export type NetworkData = {
  id: string;
  interface: string;
  urlSection: string;
  env: Array<string>;
  domain?: string;
  langPos?: string;
  lang?: string;
  langs?: Array<string>;
  bgs?: Array<string>;
  theme?: string;
  logo?: string;
  advertiser?: Advertiser;
  pageTitle?: string;
  inlineStylesheet?: string;
  favicon?: Favicon;
  fields?: Array<string>;
  locationOrder?: string;
  segmentOrder?: string;
  locationLocale?: string;
  hideSmoothing?: boolean;
  hideDateTimes?: boolean;
  stylesheets?: Array<string>;
  hasAnonymousCampaigns?: boolean;
  hasCreativeCopyrightStatus?: boolean;
  hasSignUpCondition?: boolean;
  hasSidebar?: boolean;
  showCoupons?: boolean;
};

export const REGISTERED_URLS: Array<NetworkData> = [
  {
    id: "da_trippers",
    interface: "DA_TRIPPERS",
    urlSection: "datrippers",
    env: ["production"],
    lang: "el",
    langs: ["el", "en", "in", "de"],
    fields: ['rendered', 'clicks', 'ctr'],
    hasAnonymousCampaigns: true,
    hasCreativeCopyrightStatus: true,
    hasSidebar: true,
    showCoupons: true
  },
  {
    id: "ais",
    interface: "AIS",
    urlSection: "ais",
    domain: "ads.than.th",
    bgs: ["ais"],
    pageTitle: 'AIS',
    env: ["production"],
    logo: 'https://regalia.adnuntius.com/label/ais/THAN-200x40.png'
  },
  {
    id: "network_1",
    interface: "",
    urlSection: "net",
    env: ["localhost", "lcl"]
  },
  {
    id: "network_2",
    interface: "",
    urlSection: "net2",
    env: ["localhost", "lcl"]
  },
  {
    id: "vjvv",
    interface: "PRISA",
    urlSection: "prisa",
    bgs: ['prisa'],
    env: ["production"],
    pageTitle: 'Prisa',
    domain: "selfservice.prisabs.com",
    favicon: {
      href: "https://regalia.adnuntius.com/label/prisa/favicon.png",
      rel: "icon",
      type: "image/png"
    }
  },
  {
    id: "nkjm",
    interface: "MEDIASQUARE",
    urlSection: "mediasquare",
    env: ["production"],
    theme: 'mediasquare',
    bgs: ['mediasquare'],
    logo: 'https://assets.adnuntius.com/ZxrPk71X0QRHz9VjoaGyubjZyPRrXl8CMd0SZJMwy7c.png',
    favicon: {
      href: 'https://assets.adnuntius.com/Tfg7g0v7LP9T2X-J_gdn12k1PjFsmpTUFMzAMO6ARmw.png',
      rel: 'icon',
      type: 'image/jpg'
    },
  },
  {
    id: "d966",
    interface: "PHILSTAR",
    urlSection: "philstar",
    env: ["production"],
    theme: 'philstar',
    bgs: ['philstar'],
    logo: 'https://regalia.adnuntius.com/label/philstar/logo.png',
    domain: 'https://advertisingadmin.philstar.com',
    favicon: {
      href: 'https://regalia.adnuntius.com/label/philstar/favicon.jpg',
      rel: 'icon',
      type: 'image/jpg'
    },
  },
  {
    id: "blueoryxcalt",
    interface: "BLUEORYX",
    urlSection: "blueoryx",
    env: ["production"],
    theme: 'blueoryxcalt',
    bgs: ['blueoryxcalt'],
    logo: 'https://assets.adnuntius.com/E05BHs-mD0DCa-1-osghR8fnvRs07Eqz1V2MF3X3QCc.png',
    domain: 'dsp.blueoryx.no',
    favicon: {
      href: 'https://assets.adnuntius.com/E05BHs-mD0DCa-1-osghR8fnvRs07Eqz1V2MF3X3QCc.png',
      rel: 'icon',
      type: 'image/png'
    },
  }
];